:root {
  --header-bg-opacity-top: 0.7;
  --header-bg-opacity-bottom: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  will-change: transform, background-color;
}

.header.visible {
  transform: translateY(0);
}

.header.hidden {
  transform: translateY(-100%);
}

.header.at-top {
  background: linear-gradient(
    to bottom, 
    rgba(0, 0, 0, var(--header-bg-opacity-top)) 0%, 
    rgba(0, 0, 0, var(--header-bg-opacity-bottom)) 100%
  );
}

.header.not-at-top {
  background-color: rgba(0, 0, 0, var(--header-bg-opacity-top));
}

.logo-container {
  display: flex;
  align-items: flex-end; /* 改为底部对齐 */
}

.logo {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px; /* 增加右侧间距 */
}

.icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.logo-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* 改为底部对齐 */
}

.title, .subtitle {
  margin: 0;
  padding: 0;
  line-height: 1.2;
  white-space: nowrap;
  color: var(--header-text-color);
}

.title {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 5px; /* 增加标题和副标题之间的间距 */
}

.subtitle {
  font-size: 14px;
  letter-spacing: 2px;
}

nav ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin: 0 3rem; /* 将间距从2rem增加到3rem */
}

nav ul li a {
  text-decoration: none;
  color: var(--header-text-color);
  font-weight: bold;
  font-size: 20px;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: var(--accent-color);
}

.cooperation-btn {
  padding: 0.5rem 1rem;
  background-color: transparent;
  color: var(--header-text-color);
  border: 1px solid var(--header-text-color);
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-right: 20px; /* 添加这行，将按钮向左移动 */
}

.cooperation-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

@media (max-width: 720px) {
  .header {
    flex-direction: column;
    padding: 1rem;
  }

  .logo-container {
    margin-bottom: 1rem;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav ul li {
    margin: 0.5rem 0;
  }

  .cooperation-btn {
    margin-top: 1rem;
    margin-right: 0; /* 在移动设备上取消右边距 */
  }
}
