html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

main {
  flex: 1;
}

h1, h2, h3 {
  margin-bottom: 20px;
  color: var(--text-color);
}

section {
  padding: 80px 50px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  background-color: transparent;
}

.cta-button {
  background-color: var(--primary-color);
  color: var(--header-text-color);
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  border-radius: 30px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.cta-button:hover {
  background-color: var(--accent-color);
  transform: translateY(-2px);
}

.cta-button.secondary {
  background-color: transparent;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}

.cta-button.secondary:hover {
  background-color: var(--primary-color);
  color: var(--header-text-color);
}

/* 添加更多样式以匹配智谱AI的设计 */

@media (max-width: 720px) {
  .App {
    min-width: auto;
    width: 100%;
  }

  body {
    width: 100%;
  }

  section {
    padding: 40px 20px;
  }
}
