.mission {
  position: relative;
  height: 75vh; /* 5/6 of the viewport height */
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: white;
}

.mission-content {
  max-width: 1200px;
  width: 90%;
  padding: 2rem;
  text-align: center;
  z-index: 2;
}

.mission h2 {
  font-size: 3.5rem; /* 增大标题字体 */
  margin-bottom: 1.5rem;
  color: white;
}

.mission-slogan {
  font-size: 1.8rem; /* 增大口号字体 */
  margin-bottom: 2.5rem;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
}

.mission-items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2.5rem;
}

.mission-item {
  flex: 1;
  min-width: 200px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  padding: 1.5rem;
  text-align: left;
  width: calc(25% - 1rem);
}

.mission-item h3 {
  color: #3366ff;
  margin-bottom: 0.8rem;
  font-size: 1.5rem; /* 增大子标题字体 */
}

.mission-item p {
  font-size: 1.1rem; /* 增大段落字体 */
  line-height: 1.6;
}

@media (max-width: 1200px) {
  .mission-item {
    flex: 1 1 calc(50% - 2rem);
    max-width: calc(50% - 2rem);
  }
}

@media (max-width: 768px) {
  .mission {
    height: auto; /* 在移动设备上允许高度自适应内容 */
    min-height: 83.33vh; /* 确保最小高度仍为窗口高度的 5/6 */
  }

  .mission h2 {
    font-size: 3rem; /* 在移动设备上稍微减小标题字体 */
  }

  .mission-slogan {
    font-size: 1.6rem; /* 在移动设备上稍微减小口号字体 */
  }

  .mission-items {
    flex-direction: column;
  }

  .mission-item {
    width: 100%;
  }

  .mission-item h3 {
    font-size: 1.4rem; /* 在移动设备上稍微减小子标题字体 */
  }

  .mission-item p {
    font-size: 1rem; /* 在移动设备上稍微减小段落字体 */
  }
}
