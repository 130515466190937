/* 隐藏滚动条但保持滚动功能 */
html, body {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

/* 对于 Webkit 浏览器（Chrome、Safari 等） */
html::-webkit-scrollbar, 
body::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
}
body {
  overflow-y: auto !important;
}

