/* ... 保留之前的样式 ... */

.about {
  position: relative;
  padding: 4rem 0;
  color: white;
  text-align: center;
  min-height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-content {
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.about::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.234);
  z-index: 1;
}

.about h2 {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: white;
}

.about h3 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: white;
}

.team-structure {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ceo, .assistant {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  width: 15%;
  max-width: 25%;
}

.ceo {
  background-color: rgba(29, 88, 65, 0.497);
  margin-bottom: 2rem;
}

.role, .name {
  display: block;
  margin-bottom: 0.5rem;
}

.ceo .role {
  font-size: 1.2rem;
  font-weight: bold;
}

.ceo .name {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.assistants {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}

button {
  background-color: #3366ff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #254EDB;
}

@media (max-width: 768px) {
  .about h2 {
    font-size: 2.5rem;
  }

  .about h3 {
    font-size: 1.3rem;
  }

  .ceo, .assistant {
    max-width: 100%;
  }

  .assistants {
    flex-direction: column;
    align-items: center;
  }
}
