.footer {
  background-color: #1a1a1a;
  color: white;
  padding: 2rem 0;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 2rem;
}

.footer-left, .footer-center, .footer-right {
  flex: 1;
}

.logo-container {
  display: flex;
  align-items: flex-end;
}

.logo {
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.logo-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.title, .subtitle {
  margin: 0;
  padding: 0;
  line-height: 1.2;
  white-space: nowrap;
  color: white;
}

.title {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 5px;
}

.subtitle {
  font-size: 14px;
  letter-spacing: 2px;
}

.qr-codes {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.qr-code {
  text-align: center;
}

.qr-code img {
  width: 90px;
  height: 90px;
}

.qr-code p {
  margin-top: 0.1rem;
  font-size: 0.8rem;
}

.footer-right {
  display: flex;
  justify-content: flex-end;
}

.footer-info {
  text-align: left;
}

.footer-info p {
  font-size: 0.9rem;
  margin: 0.5rem 0;
}A

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
  }

  .footer-left, .footer-center, .footer-right {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }

  .logo-container {
    justify-content: center;
  }

  .qr-codes {
    justify-content: center;
  }

  .footer-right, .footer-info {
    justify-content: center;
    text-align: center;
  }
}
