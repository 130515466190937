:root {
    --header-height: 0px; /* 替换为你的 header 实际高度 */
    --vh : 0px;
  }
.hero {
  position: relative;
  width: 100vw;
  /* height: calc(100vh - var(--header-height)); */
  /* height: calc(var(--vh, 80vh)); */
  height: 87vh;
  overflow: auto;
  /* max-height: calc(100vh - var(--header-height)); */
  /* max-height: calc(var(--vh, 80vh)); */
  max-height: 100vh;
  /* background: linear-gradient(to bottom, #000000, #1a1a1a); */
}

.hero-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  z-index: 1;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.2) 100%);
  z-index: 2;
}

.hero-content {
  position: absolute;
  bottom: 9%;  /* 从底部预留20%的空间 */
  left: 50%;
  transform: translateX(-50%);  /* 只在水平方向上居中 */
  z-index: 3;
  text-align: center;
  color: white;
  padding: 0 20px;
  max-width: 1200px;
  width: 100%;
}

.hero h1 {
  font-size: 4rem;
  font-weight: 700;
  color: white;
  margin-bottom: 1.5rem;
  letter-spacing: 1px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);  /* 添加文字阴影 */
}
.hero h2 {
    font-size: 3cap;
    font-weight: 700;
    color: white;
    margin-bottom: 1.5rem;
    letter-spacing: 1px;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);  /* 添加文字阴影 */
  }
.hero p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.cta-button {
  padding: 0.8rem 2rem;
  font-size: 1.1rem;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
}

.cta-button.primary {
  background-color: #3366ff;
  color: white;
}

.cta-button.secondary {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: white;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.scroll-down-arrow {
  animation: bounce 2s infinite;
}

.scroll-down-arrow img {
  width: 30px;
  height: 30px;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
    text-shadow: 1px 1px 3px rgba(0,0,0,0.5);  /* 为移动设备调整阴影 */
  }

  .hero p {
    font-size: 1.2rem;
  }

  .cta-buttons {
    flex-direction: column;
    align-items: center;
  }

  .cta-button {
    width: 80%;
    margin-bottom: 1rem;
  }

  .hero-background {
    /* 移除 width: auto; height: 100%; 因为 object-fit: cover 已经能处理这种情况 */
  }
}

/* 保留移动设备的媒体查询 */
@media (max-width: 768px) {
  .hero-background {
    /* 移除 width: auto; height: 100%; 因为 object-fit: cover 已经能处理这种情况 */
  }
}

@media (max-height: 600px) {
  .hero {
    height: auto;
    min-height: calc(100vh - var(--header-height));
  }

  .hero-content {
    bottom: 10%;  /* 在较小的屏幕上减少底部空间 */
    padding: 20px;
  }

  .hero h1 {
    font-size: 2rem;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);  /* 为小屏幕调整阴影 */
  }

  .hero p {
    font-size: 1rem;
  }
}

/* 移除或注释掉以下媒体查询，因为 object-fit: cover 已经能很好地处理不同宽高比 */
/*
@media (max-aspect-ratio: 16/9) {
  .hero-video {
    width: 100%;
    height: auto;
  }
}

@media (min-aspect-ratio: 16/9) {
  .hero-video {
    width: auto;
    height: 100%;
  }
}
*/
